// Switch Css
.dm-switch-wrap {
  flex-wrap: wrap;
  .form-switch{
    margin-right: 10px;
  }
}
.form-check-input {
  clear: left;
}

.form-switch {
  .form-check-input {
    width:1.80rem;
    height: 0.90rem;
    border-radius: 2rem;
  }
  &.form-switch-sm .form-check-input {
    width: 2.188rem;
    height: 1.25rem;
    border-radius: 2rem;
  }

  &.form-switch-md .form-check-input {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
  }

  &.form-switch-lg .form-check-input {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
  }

  &.form-switch-xl .form-check-input {
    height: 2.5rem;
    width: calc(4rem + 0.75rem);
    border-radius: 5rem;
  }
}

.form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}