// Tag Css
.dm-tag-wrap {
  h6 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .tag-box {
    margin: -4px;
  }
}
.dm-tag {
  font-size: 11px;
  font-weight: 500;
  display: inline-block;
  line-height: 1.4;
  padding: 4px 10px;
  border-radius: 5px;
  color:var(--color-white);
  margin: 4px;
  &.tag-primary {
    font-weight: 600;
    background-color: var(--color-primary);
    &.tag-transparented {
      background-color: rgba(var(--color-primary-rgba), 0.15);
      color:var(--color-primary);
    }
  }
  &.tag-secondary {
    font-weight: 600;
   background-color:var(--color-secondary);
    &.tag-transparented {
      background-color: rgba(var(--color-secondary-rgba), 0.15);
      color:var(--color-secondary);
    }
  }
  &.tag-success {
    font-weight: 600;
   background-color:var(--color-success);
    &.tag-transparented {
      background-color: rgba($success, 0.15);
     color:var(--color-success);
    }
  }
  &.tag-info {
    font-weight: 600;
   background-color:var(--color-info);
    &.tag-transparented {
      background-color: rgba(var(--color-info-rgba), 0.15);
      color:var(--color-info);
    }
  }
  &.tag-warning {
    font-weight: 600;
   background-color:var(--color-warning);
    &.tag-transparented {
      background-color: rgba(var(--color-warning-rgba), 0.15);
      color:var(--color-warning);
    }
  }
  &.tag-danger {
    font-weight: 600;
   background-color:var(--color-danger);
    &.tag-transparented {
      background-color: rgba(var(--color-danger-rgba), 0.15);
      color:var(--color-danger);
    }
  }
  &.tag-light {
    font-weight: 500;
    color:var(--color-gray);
    background-color: var(--bg-deep);
    &.tag-transparented {
      background-color: rgba(var(--light-gray-rgba), 0.15);
      color:var(--color-light);
    }
  }
  i,
  span,
  span {
    font-size: 10px;
    color:var(--color-gray);
  }
  a {
    color:var(--color-gray);
  }
}

.dm-tag-mode {
  .select2-search__field {
    text-align: left;
  }
}
