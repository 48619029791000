.modal-custom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11111111;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: scroll;
  max-height: calc(100% - 30px);
  width: calc(100% - 30px);
  padding: 0;

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  &::-webkit-scrollbar {
    display: none;
  }
  &--active {
    opacity: 1;
    visibility: visible;
  }
  &-body {
    height: 100%;
    overflow: scroll;
    padding: 24px;
  }
}
.modal-custom-full {
  max-height: calc(100% - 30px);
}
