.nav-tabs .nav-link.active {
  background-color: transparent !important;
  padding: 12px 50px;
}
.nav-tabs .nav-link {
  transition: all 0.3s;
  padding: 12px 15px;
  user-select: none;
  cursor: pointer;
}
.dm-tab .nav-tabs:after {
  background-color: #f0f0f0;
}
.nav-tabs {
}
.dm-tab .nav-tabs {
  position: sticky;
  top: 150px;
  background-color: var(--contents-bg);
}
// .dm-nav-controller .nav .nav-link:last-child {
//   border-radius: 0 21px 21px 0;
// }
// .dm-nav-controller .nav .nav-link:first-child {
//   border-radius: 21px 0 0 21px;
// }

.dm-nav-controller .nav .nav-link.active-filled {
  background-color: var(--color-primary);
  color: #fff !important;
  border-color: var(--color-primary);
}
@media (max-width: 768px) {
  .btn-group.dm-button-group.btn-group-normal.nav {
    grid-template-columns: 1fr !important;
  }
}
