.ap-po-details-time small {
  margin-left: 10px;
}
.ap-po-details-time i {
  margin-right: 4px;
}
.order-bg-opacity-warning svg {
  fill: none !important;
}
.order-bg-opacity-blue-dark {
  background-color: rgba(49, 104, 211, 0.1);
}
