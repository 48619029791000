.wrap {
  height: 100%;
  min-height: 100vh;
  background: #131314;
  display: grid;
  grid-template-columns: auto 1fr;
  & {
    *::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: #1e1f20;
    }
    *::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      border-radius: 8px;
    }
    *::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #666667;
    }
  }
}
@supports (-webkit-touch-callout: none) {
  .wrap {
    min-height: -webkit-fill-available;
  }
  .menu {
    height: -webkit-fill-available;
  }
}
.menuExit {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  opacity: 0.8;
  & svg {
    margin-right: 17px;
  }
}
.content {
  padding: 32px 34px 27px 34px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.contentContainer {
  width: 100%;
  margin: 0 auto;
  max-width: 820px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  display: block;
  width: 90px;
}
.accountWrap {
  position: relative;
}
.account {
  display: flex;
  align-items: center;
  user-select: none;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  color: #fff;
  width: 34px;
  height: 34px;
  background: #1e1f20;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
}
.right {
  display: flex;
  align-items: center;
}
.newChat {
  width: 34px;
  height: 34px;
  background: #1a1a1c;
  border-radius: 100px;
  background-position: center;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  transition: all 0.3s;
  background-image: url(../../../../public/img/svg/add.svg);
  margin-left: 5px;
  // background-color: #666667;
}
.newChatDisabled {
  pointer-events: none;
  opacity: 0.5;
}
.email {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 34px;

  &::after {
    content: '';
    display: block;
    background-image: url(../../../../public/img/svg/arrow-bottom.svg);
    width: 12px;
    height: 7px;
    margin-left: 7px;
    transition: transform 0.3s ease-out;
    // transform: rotate(0deg);
  }
}
.emailShow {
  &::after {
    transform: scale(-1, -1);
  }
}

.menu {
  user-select: none;
  background-color: #1e1f20;
  padding: 27px;
  width: 272px;
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
  z-index: 10;
}
.menuShow {
  opacity: 1;
  visibility: visible;
}
.item {
  display: flex;
  align-items: center;
  color: #fff;
  opacity: 0.8;
  transition: all 0.3s;
  & + & {
    margin-top: 15px;
  }
  &:hover {
    opacity: 1;
    color: #fff;
  }
}
.itemIcon {
  font-size: 14px;
}
.itemName {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  margin-left: 17px;
}

.policy {
  display: inline-block;
  font-size: 14px;
  font-weight: 200;
  line-height: 14px;
  text-align: left;
  color: #ffffff !important;
  text-decoration: underline !important;
  text-underline-offset: 2px;
}
.footer {
  padding-top: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left {
  display: flex;
  align-items: center;
}
.menuBtn {
  height: 34px;
  width: 34px;
  background-position: center;
  background-size: 14px 10px;
  background-repeat: no-repeat;
  background-image: url(../../../../public/img/svg/burger.svg);
  margin-right: 7px;
}
.menuList {
  // overflow: scroll;
}
@media (max-width: 992px) {
  .menuExit {
    bottom: 34px;
    left: 17px;
    width: calc(100% - 17px - 17px);
    transform: none;
    border-radius: 100px;
    height: 50px;
    transition: all 0.3s;
    & svg {
      margin-right: 25px;
    }
    &:active,
    &:hover {
      opacity: 1;
      background-color: #37393b;
    }
    padding: 0 20px;
  }
  .itemName {
    margin-left: 25px;
  }
  .item {
    border-radius: 100px;
    margin: 0 !important;
    height: 50px;
    padding: 0 20px;
    &:active,
    &:hover {
      background-color: #37393b;
    }
  }
  .menuEmail {
    position: absolute;
    top: 16px;
    right: 87px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    transition: all 0.1s ease-out;
    opacity: 0;
    visibility: hidden;
    // transition-delay: 1s !important;
  }
  .avatarShow {
    background-color: #37393b;
  }
  .menuEmailShow {
    transition: all 0.2s ease-out 0.1s;
    opacity: 1;
    visibility: visible;
  }

  .menu {
    width: 300px;
    padding: 150px 17px 84px 17px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
    transform: translateX(100%);
  }

  .menuShow {
    transform: translateX(0%);
  }
  .footer {
    padding-top: 22px;
  }
  .account {
    position: relative;
    z-index: 100;
  }
  .avatar {
    transition: all 0.3s;
    margin-right: 0;
  }
  .content {
    padding: 13px 17px 29px 17px;
  }
  .wrap {
    grid-template-columns: 1fr;
  }
  .header {
    border-bottom: 0.5px solid #ffffff1a;
    padding: 0 17px 12px 17px;
    margin-left: -17px;
    width: calc(100% + 34px);
  }
  .email {
    & span {
      display: none;
    }
  }
}
