// Input Css
.input-list-wrap{
    margin-bottom: -10px;
}
.input-list{
    @include e("single"){
        margin: 0 10px 10px 0;
        flex:0 0 33.33%;
        @include xl{
            flex: 33.33%;
        }
        @include ssm{
            flex:100%;
        }
    }
}

.input-container{
    .input-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        line-height: 0;
        &.icon-left{
            left: 20px;
        }
        &.icon-right{
            right: 20px;
        }
        svg,
        img{
            width: 14px;
            color:var(--color-lighten);
        }
    }
    &.icon-left{
        input{
            padding-left: 40px;
        }
    }
}

.form-group{
    input{
        &::placeholder{
            color:var(--color-light);
        }
    }
}