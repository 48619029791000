/*PRELOADING------------ */

#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  background: var(--color-white);
  top: 0;
  left: 0;
  max-height: 100vh;
  visibility: visible;
  opacity: 1;
}
.loader-overlay {
  display: inline-block;
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body {
  &.loaded,
  &[cz-shortcut-listen='true'] {
    &:after {
      visibility: hidden;
      opacity: 0;
    }
    #overlayer {
      visibility: hidden;
      opacity: 0;
    }
  }
  &:after {
    content: '';
    background: var(--color-white);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99998;
    position: absolute;
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    display: none;
  }
}

.overlayScroll {
  overflow: hidden;
}
