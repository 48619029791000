/* Ticket breadcrumb */
.breadcrumb-main--ticket {
    margin: 0;

    .action-btn {
        a {
            height: 40px;
            border-radius: 6px;
            padding: 0 20px;
            font-size: 14px;
            font-weight: 500;
            outline: none;

            &:focus {
                box-shadow: none;
            }

            i {
                font-size: 10px;
            }
        }
    }

}

/* Table Breadcrumb */
.breadcrumb-main--table{
    .action-btn{
        a{
            font-size: 14px;
            font-weight: 500;
            height:40px;
            min-height: 40px;
            i{
                margin-left:7.5px ;
                margin-right: 0;
                font-size: 14px;
            }
        }
    }
    .breadcrumb-title{
       @include rfs(18px);
    }
}

.ticket_modal {
    .modal-content {
        box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), .20);
        .modal-header{
            display: flex;
            align-items: center;
            margin-bottom: 50px;
            border: none;
            padding: 0;
            @include ssm{
                margin-bottom: 30px;
            }
            h1 {
                @include rfs(30px);
                font-weight: 600;
                color: var(--color-dark);
                margin-bottom: 0;
            }
            .close{
                &:hover{
                    opacity: 0.7;
                }
            }
        }
        .ticket_modal-modal {

            form {
                label {
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--color-gray);
                    margin-bottom: 6px;
                }

                .form-control {
                    border-radius: 5px;
                    border: 1px solid var(--border-color);
                    height: 50px;

                    &:focus {
                        border-color: var(--color-primary);
                    }
                }

                textarea {
                    &.form-control {
                        min-height: 130px;
                        resize: none;

                        &:focus {
                            border-color: var(--color-primary);
                        }
                    }
                }

                .button-group {
                    .btn {
                        text-transform: capitalize;
                        transition: 0.3s ease-in-out;
                        padding: 0 30px;
                    }
                }
            }
        }
    }
}

.new-ticket{
    .ticket_modal{
        background-color: var(--color-white);
        border-radius: 10px;
        box-shadow:0 10px 50px  rgba(var(--color-lighten-rgba), .20);
        padding: 50px 60px;
        @include sm{
            padding: 20px 30px;
        }
        .ticket_modal-modal {
            h1 {
                @include rfs(30px);
                font-weight: 600;
                color: var(--color-dark);
                margin-bottom: 50px;
                @include ssm{
                    margin-bottom: 30px;
                }
            }

            form {
                label {
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--color-gray);
                    margin-bottom: 6px;
                }

                .form-control {
                    border-radius: 5px;
                    border: 1px solid var(--border-color);
                    height: 50px;
                    transition: var(--transition);
                    box-shadow: none;

                    &:focus {
                        border-color: var(--color-primary);
                    }
                }

                textarea {
                    &.form-control {
                        min-height: 130px;
                        resize: none;

                        &:focus {
                            border-color: var(--color-primary);
                        }
                    }
                }

                .button-group {
                    .btn {
                        text-transform: capitalize;
                        transition: 0.3s ease-in-out;
                        padding: 3px 30px;
                    }
                }
            }
        }
    }
}

/* Support ticket */
.support-ticket-system {
    padding: 25px;
    border: none;
    box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), .05);
    border-radius: 10px;
    background-color: var(--color-white);
    margin-bottom: 30px;
}

.support-ticket-system--search{
    .support-form__input{
        >div{
            @include sm{
                flex-direction: column;
                .support-form__input-id{
                    margin-right: 0;
                    margin-bottom: 15px;
                }
                .support-form__input-status,
                .support-form__input-button{
                    margin-left: 0;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.support-form {
    @include sm{
        flex-direction: column;
        justify-content: center;
    }
    .support-form__input {
        margin: 20px 0 20px 0;
        @include ssm{
           >div{
            flex-direction: column;
           }
        }
        
        .dm-select {
            .select2 {
                min-width: 200px;
                @include ssm{
                    min-width: 160px;
                }

                .select2-selection {
                    height: 40px;
                    border-radius: 6px;
                    border: 1px solid var(--border-color);
                    box-shadow: 0 5px 5px rgba(var(--box-shadow-color-rgba), .03);
                }

                &.select2-container {
                    .select2-selection--single {
                        .select2-selection__rendered {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }

    .support-form__input-button{
        background-color:rgba(var(--color-primary-rgba), .10) ;
        border-radius: 6px;
        height: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-primary);
        font-size: 14px;
        font-weight: 500;
        line-height: lh(14px,22px);
        border: none;
        box-shadow: none;
        padding: 0 20px;
        text-transform: capitalize;
        margin-left: 20px;
        transition: var(--transition);
        &:hover{
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }

    .support-form__search {
        margin: 20px 0 20px 15px;
        @include sm{
            margin: 0 0 20px 0;
        }
    }

    .support-form__input-id,
    .support-form__input-status {
        display: flex;
        align-items: center;

        label {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-gray);
            margin-right: 10px;
            text-transform: capitalize;
        }
    }

    .support-form__input-id {
        margin: 0 30px 0 0;
        @include ssm{
            margin: 0 0 20px 0;
        }

        label {
            text-transform: uppercase;
        }
    }

    .support-order-search {
        .support-order-search__form {
            padding: 15px 20px;
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            min-width: 280px;
            border: 1px solid var(--border-color);
            box-shadow: 0 5px 5px rgba(var(--box-shadow-color-rgba), .03);
            @include ssm{
                min-width: 220px;
            }

            input {
                padding: 0;

                &::placeholder {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--color-lighten);
                }
            }
            img,
            svg {
                color: var(--color-light);
                width: 16px;
                margin-right: 10px;
            }
        }
    }
}

/* Ticket Details */
.back-page {
    margin-bottom: 15px;
    @include sm{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -10px;
    }

    a {
        font-size: 14px;
        font-weight: 500;
        line-height: lh(14px, 20px);
        color: var(--color-gray);
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        img,
        svg {
            width: 16px;
            margin-right: 6px;
        }

        &:hover {
            color: var(--color-primary);
        }
    }
}

/* Main content */
.ticket-details-contact {
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), .05);
    padding: 25px 25px 30px 25px;

    h4 {
        font-weight: 500;
        color: var(--color-dark);
        margin-bottom: 30px;
    }

    @include e(ift) {
        display: flex;
        flex-wrap: wrap;
    }

    @include e(ift-wrapper) {
        flex: 180px;
    }

    @include e(ift-rule) {
        font-size: 14px;
        font-weight: 400;
        line-height: lh(14px, 20px);
        color: var(--color-light);
        display: block;
        margin-bottom: 10px;
    }

    @include e(ift-namTitle) {
        display: inline-flex;
        align-items: center;

        img {
            margin-right: 15px;
            width: 30px;
            max-width: 30px;
            height: auto;
            object-fit: cover;
        }

        h6 {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-dark);
        }
    }

    @include e(status) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 32px;
        @include xl{
            margin-bottom: 15px;
        }
    }

    @include e(status-item) {
        flex: 0 0 180px;
        margin-right: 150px;
        @include md{    
            margin-right:100px;
        }
        @include ssm{
            margin-right: 30px;
        }
        @include xs{
            margin-bottom: 20px;
            flex: 100%;
            margin-right: 0;
        }

        label {
            font-size: 14px;
            font-weight: 500;
            line-height: lh(14px, 20px);
            color: var(--color-gray);
            margin-bottom: 15px;
        }

        .dm-select {
            width: 130px;
            @include xl{
                width: 100%;
            }

            select {
                width: 130px;
                @include xl{
                    width: 100%;
                }
            }

            .select2 {
                width: 130px !important;
                @include xl{
                    width: 100% !important;
                }
            }

            .select2-container--default {
                .select2-selection--single {
                    min-height: 36px;
                    height: 36px;
                    border-radius: 6px;
                    border: 1px solid var(--border-color);
                    transition: var(--transition);

                    &:hover {
                        border: 1px solid var(--color-primary);
                    }

                    .select2-selection__rendered {
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--color-gray);
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }

    }

    @include e(overview) {
        .ticket-details-contact__overview-title {
            color: var(--color-dark);
            @include rfs(20px);
            line-height: lh(20px, 24px);
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: lh(16px, 27px);
            color: var(--color-gray);
            margin-bottom: 0;
        }
    }
}

.ticket-file-attach {
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), .05);
    padding: 20px 25px 30px 25px;

    h4 {
        font-weight: 500;
        color: var(--color-dark);
        margin-bottom: 22px;
    }

    @include e(items) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    @include e(items_detail) {
        display: inline-flex;
        align-items: center;

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            margin-right: 10px;
        }

        h6 {
            font-size: 15px;
            font-weight: 500;
            line-height: lh(15px, 20px);
            color: var(--color-gray);
        }

        span {
            color: var(--color-light);
            font-size: 15px;
            font-weight: 400;
            line-height: lh(15px, 25px);
        }
    }

    @include e(items_download) {
        a {
            color: var(--color-light);
            img,
            svg {
                width: 16px;

            }

            &:hover {
                color: var(--color-primary);
            }
        }
    }
}

.ticket-chat-wrapper {
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), .05);
    padding: 25px 25px 30px 25px;

    h4 {
        font-weight: 500;
        color: var(--color-dark);
        margin-bottom: 22px;
    }

    .ticket-search-body {
        max-height: 220px;
        overflow: hidden auto;
        scrollbar-width: thin;
        scrollbar-color: var(--thumbBG) var(--scrollbarBG);

        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-track {
            background: var(--scrollbarBG);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--thumbBG);
            border-radius: 6px;
            border: 3px solid var(--scrollbarBG);
        }
    }

    .ticket-search-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        form{
            background-color: var(--bg-lighters);
            height: 70px;
            border-radius: 35px;
            padding: 0 25px;
            transition: all 0.3s ease;
            margin-right: 10px;
            flex: 1;
            display: flex;
            align-items: center;
            @include md{
                height: 50px;
            }
            input{
                background-color: var(--bg-lighters);
                &::placeholder{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: lh(16px, 27px);
                    color: var(--color-light);
                }
            }
            svg{
                width: 24px;
                color: var(--color-lighten);
            }
        }

        button{
            transition: var(--transition);
            @include md{
                width: 40px;
                height: 40px;
            } 
            svg{
                width: 20px;
            }
        }

    }
}

.ticket-user-list {
    .ticket-user-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .ticket-user-list-item__wrapper {
            display: flex;
            align-items: flex-start;
            width: 100%;

            .avatar {
                img {
                    width: 30px;
                    height: 30px;
                }
            }

            .ticket-users-list-body-title {
                h6 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: lh(14px, 20px);
                    color: var(--color-dark);
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: lh(16px, 27px);
                    color: var(--color-gray);
                    margin-bottom: 0;
                }
            }
        }

        .ticket-users-list-body {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
        }
    }
}