.tooltip-custom {
  --rt-opacity: 1;
  //   transform: translateX(-2);
  //   border: 1px solid var(--border-light);
  transform: translateX(-5px);
  color: var(--color-text) !important;
  font-weight: 400 !important;
  & .react-tooltip-arrow {
    transform: translateX(5px) rotate(45deg) !important;
    // border-top: 1px solid black;
    // border-left: 1px solid black;
  }
}
