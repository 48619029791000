.admin .edit-profile__logos img {
  width: 120px;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  max-height: 100vh;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s;
}
.overlay--active {
  opacity: 1 !important;
  z-index: 99999 !important;
}
.error {
  color: #ff0f0f !important;
  font-size: 14px;
  margin-top: 8px;
  white-space: pre-line;
}
.checkbox-text {
  user-select: none;
}
.navbar-right__menu .nav-item-toggle span,
.navbar-right__menu .nav-item-toggle svg {
  width: auto;
  height: auto;
}
.avatar.avatar-sm {
  width: 32px !important;
  height: 32px !important;
}
.navbar-right__menu .nav-author .nav-item__title {
  margin-left: 0 !important;
}
@media (max-width: 767px) {
  .navbar-right__menu > li .dropdown-custom {
    padding: 0;
  }
}
.dropdown-custom .dropdown-wrapper {
  top: 60px;
}
.avatar-xl {
  width: 80px;
  height: 80px;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 15px;
}
.avatar-letter {
  // text-transform: uppercase;
}
.nav-author__signout {
  cursor: pointer;
  user-select: none;
}
.banner-feature .banner-feature__shape img {
  width: 180px;
}
.telegram-btn {
  border-radius: 5px;
}
.extend-btn {
  opacity: 0.9;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
}
.extend-link {
  opacity: 1;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
.ext {
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  user-select: none;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 180, 245, 0.15) 0px 15px 20px;
  }

  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #00b4f5;
  margin: 0 10px;
  color: var(--color-dark) !important;
  font-size: 13px;
  line-height: 18px;
  &:hover .ext-open {
    transform: translateY(0%);
  }
}
.ext-open {
  position: absolute;
  top: 5px;
  right: 8px;
  transition: all 0.3s;
  transform: translateY(-130%);
}

.ext-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & img {
    margin-right: 5px;
  }
}
.ext-body {
}
@media (max-width: 700px) {
  .checkout-progress .step {
    margin-bottom: 80px !important;
  }
}
.step-text {
  height: 96px;
}
@media (max-width: 700px) {
  .step-text {
    height: auto;
  }
}
.form-control-sms {
  font-size: 20px;
  text-align: center;
  border-color: var(--color-primary) !important;
  &::placeholder {
    font-size: 20px !important;
  }
}
.shake {
  animation: horizontal-shaking 0.3s ease-in;
}

.sms-invalide {
  border-color: #ff0f0f !important;
}
@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
.rdrStaticRanges {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  width: 332px;
  position: absolute;
  top: -38px;
}
// .rdrStaticRange:nth-child(3),
// .rdrStaticRange:nth-child(6) {
//   display: none !important;
// }
.rdrDefinedRangesWrapper {
  width: 0 !important;
}
.rdrInputRanges {
  display: none;
}
.rdrStaticRangeLabel {
  padding: 10px 17px;
}
.rdrDateDisplayItem input {
  cursor: text;
}
*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: var(--scrollbarBG);
}
*::-webkit-scrollbar {
  width: 10px;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--thumbBG);
}

@media (max-width: 768px) {
  .navbar-left .navbar-brand {
    margin-right: 0px;
  }
  .navbar-left .logo-area {
    min-width: 196px;
  }
  .pricing__price .pricing_value {
    margin-top: 9px;
  }
  .pricing__price {
    flex-direction: column;
    align-items: start !important;
    justify-content: start !important;
  }
  .pricing__price .pricing_value:nth-child(2) {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
  .dm-nav-controller .nav .nav-link {
    & > div > div {
      position: relative;
    }
    & .dm-badge-list {
      position: absolute;
      top: 65%;
      transform: translateY(-50%) !important;
      left: 100%;
    }
    border-radius: 4px !important;
    // & + & {
    margin-top: 10px !important;
    &:first-child {
      margin-top: 0px !important;
    }
    // }
  }
}
.sidebar__menu-group ul.sidebar_nav li > a:hover path,
.sidebar__menu-group ul.sidebar_nav li > a.active path,
.navbar-right__menu .nav-author__options ul li a:hover path {
  fill: var(--color-primary) !important;
}
.sidebar__menu-group ul.sidebar_nav li path,
.navbar-right__menu .nav-author__options ul li path {
  transition: all 0.3s;
  fill: var(--color-lighten) !important;
}
.sidebar__menu-group ul.sidebar_nav li svg {
  margin-right: 20px;
}
.sidebar.collapsed .sidebar__menu-group li a svg {
  margin-right: 0;
}