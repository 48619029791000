//Shop Top Bar
.shop-breadcrumb {
  .action-btn .btn {
    height: 36px;
    @include ofs(12px, lh(12px, 15px), 500);
    color: var(--color-gray);
    border-radius: 3px;

    i {
      color: var(--color-primary);
    }

    &.btn-primary,
    &.btn-primary i {
      color: var(--color-white);
    }
  }
}

.shop-search .user-member__form svg {
  color: var(--color-light);
}

//Product Grid
.product_page--grid,
.product_page--list {

  .project-category p {
    margin-bottom: 0;
    margin-right: 20px;

    @include xs {
      margin: 8px 20px 8px;
    }
  }

  .project-top-left {
    @media (max-width: 414px) {
      margin-bottom: 15px;
    }
  }

  .project-top-wrapper {
    justify-content: space-between;

    @include sm {
      justify-content: center;
    }
  }

  .shop_products_top_filter {
    margin-bottom: 30px;

  }
}



.project-search {
  &.order-search {
    @include sm {
      margin: 10px 50px;

      @include ssm {
        margin: 0;
      }
    }
  }

  @include md {
    display: flex;
    justify-content: center;

    .user-member__form {
      min-width: 370px;

      @include sm {
        min-width: 350px;
      }

      @include xs {
        min-width: 250px;
        max-width: 350px;
      }

      @include xxs {
        min-width: 250px !important;
      }
    }
  }
}

.product {
  transition: var(--transition);

  &.card {
    box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.10);
  }

  .product-item__button {
    button {
      height: 36px;
      margin: 5px;
      line-height: normal;
    }
  }

}

.product--grid {
  .product-item__button {
    margin: -5px;
  }

  &:hover {
    transform: scale(1.03);

    @include xl {
      transform: unset;
    }
  }

  .product-item__body {
    .card-title {
      @include rfs(18px);
    }
  }
}

.product-item {
  .product-desc-price {
    @include ofs(14px, lh(14px, 20px), 600);
    color: var(--color-primary);
  }

  .product-price {
    @include ofs(14px, lh(14px, 20px), 400);
    color: var(--color-lighten);
    text-decoration: line-through;
    margin-right: 10px;
    margin-left: 10px;
  }

  .product-discount {
    @include ofs(12px, lh(12px, 18px), 500);
    color: var(--color-secondary);
  }

  @include e(body) {
    .card-title {
      margin-bottom: 9px;
      text-transform: capitalize;
    }
  }

  @include e(button) {

    button {
      height: 34px;
      @include ofs(12px, lh(12px, 15px), 500);
      color: var(--color-gray);
      text-transform: capitalize;
      border: 1px solid var(--border-light);
      border-radius: 4px;
      img,
      svg {
        width: 13.46px;
        height: 14.81px;
      }

      &.btn-primary {
        color: var(--color-white);
        box-shadow: 0 3px 5px #20C9970D;
      }
    }
  }
}

//Product List

.product--list {
  .product-item {
    .product-item__image {
      width: 300px;
      height: 200px;

      @include xl {
        width: 300px;
        height: unset;

        img {
          height: unset;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }

  .like-icon {
    top: 0;

    @include xl {
      right: -10px;
      top: -5px;
    }
  }
}

.product--list .product-item {
  padding: 20px;

  .product-item__title {
    p {
      @include ofs(15px, lh(15px, 24px), 400);
      color: var(--color-gray);
      margin-top: 17px;
    }
  }

  @include media-breakpoint-up(xl) {
    .product-item__title {
      flex: 1.5;
      padding: 0 30px;
    }

    .product-item__content {
      flex: 1;
      padding: 0 30px;
    }
  }

  @include lg {
    .product-item__title {
      flex: 1.5;
      padding: 0 15px;
      padding-right: 0;
    }

    .product-item__content {
      flex: 1;
      padding: 0 15px;
    }

    .product-item__image {
      width: 307px;
      height: auto;
    }
  }

  @include ssm {
    .like-icon {
      top: 50%;
      right: -28px;
      transform: translate(-50%, -50%);
    }

    .product-item__image {
      width: 100%;
      height: 100%;
    }

    .product-item__title {
      padding-left: 0;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .product-item__content {
      padding: 0;
    }
  }
}

.product--list .product-item,
.product--list .product-item__body {
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.product--list .product-item__body {
  .product-item__button {
    margin-top: 30px;

    @include md {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-up(md) {

    .product-item__button button {
      width: 132px;
      height: 38px;
    }
  }

  .card-title {
    @include ofs(18px, null, null);
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .card-title+p {
    @include ofs(15px, lh(15px, 25px), 400);
    color: var(--color-gray);
    margin-top: 13px;
  }
}

//Star Rating
.stars-rating {
  line-height: normal;

  .star-icon {
    @include ofs(13px, null, null);

    &.active {
      color: var(--color-warning);
    }

    &.inactive {
      color: #C6D0DC;
    }
  }

  span {
    &.active {
      color: var(--color-warning);
    }

    &.inactive {
      color: #C6D0DC;
    }
  }

  @include e(point) {
    @include ofs(12px, lh(12px, 15px), 500);
    color: var(--color-dark);
    margin-left: 6px;
    margin-right: 10px;

  }

  @include e(review) {
    @include ofs(12px, lh(12px, 15px), 400);
    color: var(--color-lighten);
  }
}

.product-ratings {
  .checkbox-text {
    @include ofs(13px, null, 400);
    color: var(--color-light);
    text-transform: lowercase;
  }

  .stars-rating .active {
    font-size: 14px;
  }
}

//Like Icon
.like-icon {
  position: absolute;
  @include ofs(15px, lh(15px, 27px), 400);
  @extend .wh-34;
  background-color: var(--color-white);
  @extend .content-center;
  box-shadow: 0 5px 10px var(--shadow3);
  right: 15px;
  top: 15px;
  border-radius: 50%;

  button {
    color: var(--color-light);
    border: none;
    box-shadow: none;
    background: transparent;

    .icon {
      @include ofs(16px, null, null);

      &.las {
        color: var(--color-danger);
      }
    }
  }
}

//Shop Sidebar
.bookmark-page,
.products_page {

  .columns-1,
  .columns-2 {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }

  .columns-1 {
    @media (min-width: 1600px) {
      flex: 0 0 25%;
      max-width: 25%;
      width: 100%;
    }

    @include cMq(1200px, 1599px) {
      max-width: 30%;
      flex: 0 0 30%;
      width: 100%;
    }
  }

  .columns-2 {
    @media (min-width: 1600px) {
      flex: 1 0 75%;
      max-width: 75%;
    }

    @include cMq(1200px, 1599px) {
      max-width: 70%;
      flex: 0 0 70%;
    }
  }
}

.bookmark-page__list,
.product-page-list {
  @media (min-width: 1600px) {
    .cus-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

}

.bookmark-page__list .cus-xl-3 {
  padding: 0 12.50px;
}

.products_page {
  .widget {
    background-color: var(--color-white);
    box-shadow: 0 5px 20px rgba(var(--box-shadow-color-rgba), 0.03);
    border-radius: 10px;
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .category_sidebar {
    padding: 25px;

    @include xl {
      padding: 15px;
    }

    @include lg {
      padding: 25px;
    }
  }

  .widget-header-title {
    border-bottom: 1px solid var(--border-color);
    img,
    svg {
      color: var(--color-light);
      width: 14px;
      height: 20px;
      margin-right: 12px;
    }

    h6 {
      @include ofs(16px, lh(16px, 20px), 500);
    }
  }
}

.product-sidebar-widget {
  .widget_title {
    h6 {
      @include ofs(15px, lh(15px, 20px), null);
      position: relative;
      text-transform: capitalize;

      &:before {
        content: "\f107";
        position: absolute;
        font-size: 12px;
        font-family: "Line Awesome Free";
        font-weight: 900;
        margin-left: auto;
        transition: var(--transition);
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        color: var(--color-primary);
      }
    }

    .widget_title__distence {
      font-size: 15px;
      font-weight: 400;
      line-height: lh(15px, 20px);
      color: var(--color-dark);
      margin-top: 4px;

      strong {
        font-weight: 600;
      }
    }

    &.nocollapse {
      h6 {
        &::before {
          display: none;
        }
      }
    }

    &.collapsed {
      h6 {
        &:before {
          content: "\f105";
          color: var(--color-gray);
        }
      }
    }
  }

  .stars-rating {
    margin-top: -2px;
  }
}

//Category Widget

.product-ratings,
.product-brands,
.product-category {
  ul li {
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    label {
      width: 100%;
    }

    .item-numbers {
      float: right;
      color: var(--color-light);
      @include ofs(12px, null, 400);
      margin-top: 2px;
    }
  }
}

.product-brands {
  .checkbox-text {
    font-size: 14px;
    color: var(--color-gray);
  }
}

.product-ratings {
  .checkbox-text {
    width: 100%;
    margin-left: 10px;
  }
}

/* See more & see less */

.limit-list-item {
  .more {
    color: var(--color-primary);
    text-transform: capitalize;
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
    font-size: 13px;
    font-weight: 500;

    &:before {
      content: "\f105";
      font-size: 12px;
      font-family: "Line Awesome Free";
      font-weight: 900;
      margin-left: auto;
      position: absolute;
      right: -20px;
      transition: var(--transition);
    }

    &.less {
      &:before {
        content: "\f107";
      }
    }
  }
}

//Price Ranges
#price-range {
  width: 100%;
  margin: 5% auto;

  .price-value {
    @include ofs(14px, lh(14px, 20px), 500);
    color: var(--color-dark);
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background-color: var(--color-white);
  border-radius: 50%;
}


.ui-slider-horizontal {
  .ui-slider-handle {
    top: -9px;
  }

  height: 3px;
  border: 0;
  background-color: rgba(var(--color-primary-rgba), 0.20);
  border-radius: 10px;
}


.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  box-sizing: border-box;
  outline: 0;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider {
  position: relative;
  text-align: left;

  .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 22px;
    height: 22px;
    cursor: default;
    touch-action: none;
    background: var(--color-white);
    border: none;

    &:before {
      position: absolute;
      content: '';
      background: var(--color-primary);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      position: absolute;
      content: '';
      background: var(--color-white);
      height: 13px;
      width: 13px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }

  .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
    background-color: var(--color-primary);
  }
}

//Product Details
.product-details {
  &.card {
    box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
    margin-bottom: 140px;

    @include sm {
      margin-bottom: 80px;
    }
  }

  @include e(availability) {
    .title {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 7px;
      }

      p {
        @include ofs(14px, lh(14px, 20px), 500);
        color: var(--color-dark);
        margin-bottom: 0;
        text-transform: capitalize;
        flex-basis: 65px;
        margin-bottom: 0;

      }

      span {
        margin-left: 30px;
        @include ofs(14px, lh(14px, 20px), 500);

        &.stock {
          color: var(--color-success);
        }

        &.free {
          font-weight: 400;
          color: var(--color-light);
        }
      }
    }
  }

  .product-item__body {
    flex-basis: 43%;

    .product-item__title {
      .card-title {
        margin-bottom: 10px;
      }
    }

    .stars-rating {
      .star-icon {
        font-size: 15px;
      }

      .stars-rating__point {
        font-size: 15px;
        font-weight: 600;
      }

      .stars-rating__review {
        font-size: 15px;
      }
    }

    .product-desc-price {
      display: flex;
      align-items: center;
      @include ofs(22px, lh(22px, 20px), 500);
      margin-top: 30px;
      margin-bottom: 15px;

      sub {
        font-size: 14px;
        color: var(--color-lighten);
      }
    }

    .product-price {
      @include ofs(16px, lh(16px, 20px), 400);
      margin-left: 0;
    }
  }

  .product-details-brandName {
    @include ofs(13px, lh(13px, 20px), 400);
    color: var(--color-lighten);
    display: flex;
    margin-top: 10px;
    align-items: center;

    span {
      color: var(--color-dark);
      font-weight: 500;
      margin-left: 6px;
    }
  }

  .product-deatils-pera {
    @include ofs(16px, lh(16px, 27px), 400);
    color: var(--color-gray);
    margin: 24px 0 22px 0;
  }

  .product-item__button {
    @include sm {
      flex-flow: column;
      align-items: flex-start;
    }

    button {
      color: var(--color-white);
      height: 44px;
      padding: 14px 30px;
      border-radius: 6px;
      @include ofs(14px, null, 500);
      img,
      svg {
        width: 15px;
      }
    }

    .like-icon {
      position: unset;
      box-shadow: 0 5px 15px var(--shadow5);
      width: 44px;
      height: 44px;
      margin-right: 10px;

      button {
        color: var(--color-light);
        padding: 0;
      }
    }
  }
}

@include md {

  .project-tap .nav-link {
    font-size: 13px;
    padding: 0 12px;

    @include sm {
      padding: 0 7.5px;
    }
  }
}

.project-top-left {
  @include xxl {
    margin-bottom: 20px;
  }

  @include lg {
    margin-bottom: 20px;
  }
}

.project-top-left,
.project-top-right {
  @include xxl {
    width: 100%;
    justify-content: space-between;
  }

  @include sm {
    justify-content: center;

  }
}

.shop_products_top_filter {

  .project-top-left {
    @include md {

      .project-search,
      .project-result-showing {
        width: 100%;
      }
    }
  }

  .project-top-right {
    @include md {

      .project-category,
      .project-icon-selected {
        width: 100%;
      }

      .project-category {
        justify-content: center;
      }
    }
  }
}

//Product Details Social Icon
.product-deatils__social li {
  a {
    color: var(--color-gray);
    font-size: 16px;
    &:hover{
      color: var(--color-primary);
    }
  }

  &:not(:first-child) {
    a {
      margin-left: 10px;
    }
  }

}

.product-item__image {
  .carousel-inner {
    max-width: 580px;
  }

  .list-thumb-gallery {
    padding: 0;
    list-style-type: none;
    padding-top: 1rem;
    list-style-type: none;

    >li {
      margin-bottom: 1rem;
      width: 80px;
      height: 80px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        background-color: #fafafa;
      }

    }
  }

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none;
  }

  .carousel-item {
    width: 580px;
    height: 450px;
    max-width: 100%;

    @include xxl {
      width: 100%;
      height: 400px;
    }

    @include lg {
      height: 350px;
    }

    @include md {
      height: 100%;
    }

    img {
      border-radius: 10px;
      width: 100%;
      height: 450px;
      object-fit: contain;
      background-color: #fafafa;

      @include xl {
        height: 100%;
      }
    }
  }
}



//Product Details Quantity

.quantity {
  display: flex;
  align-items: center;

  .input {
    width: 38px;
    padding: 0;
    margin: 0;
    text-align: center;
    border: none;
    background: transparent;
    @include ofs(14px, lh(14px, 20px), 500);
    color: var(--color-dark);

  }

  .bttn {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    @extend .content-center;
    color: var(--color-gray);
    text-decoration: none;
    @include ofs(20px, null, null);
    transition: var(--transition);

    &:hover {
      background: rgba(var(--color-primary-rgba), 0.10);
      color: var(--color-primary);
      border-color: rgba(var(--color-primary-rgba), 0.10);
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.product-quantity {
  margin-top: 25px;

  .bttn {
    width: 38px;
    height: 38px;
  }

  p {
    margin-bottom: 0;
    margin-right: 40px;
  }

  button+span {
    margin-left: 15px;
  }

}

//Breadcrumb
.shop-breadcrumb {
  @include sm {
    .breadcrumb-main {
      flex-flow: column;
      align-items: center;
      margin-bottom: 19px;

      h4 {
        margin-bottom: 6px;
      }
    }
  }
}

.product-item__content {
  .product-item__button {
    margin-bottom: -5px;

    .product-item__action {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .product-deatils__social {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}