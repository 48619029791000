.wrap {
  display: grid;

  grid-template-rows: calc(100vh - 400px) 64px;
  row-gap: 100px;
  padding-top: 102px;
  position: relative;
}
.messageList {
  overflow: auto;
}
.emptyMessages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #535354;
  font-size: 20px;
  font-weight: 600;
}
