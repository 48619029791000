.notification-modal-box {
    @include e(btn) {
        font-size: 14px;
        padding:0 15px;
        line-height: 34px;
        font-weight: 400;
        border-radius: 5px;
    }
}

// Notification Css
.notification-wrapper {
    position: fixed;
    z-index: 9999;

    &.top-left {
        top: 20px;
        left: 20px;

        @include xs {
            left: 10px;
        }
    }

    &.top-right {
        top: 20px;
        right: 20px;

        @include xs {
            right: 10px;
        }
    }

    &.bottom-left {
        bottom: 20px;
        left: 20px;

        @include xs {
            left: 10px;
        }
    }

    &.bottom-right {
        bottom: 20px;
        right: 20px;

        @include xs {
            right: 10px;
        }
    }
}

.dm-notification-box {
    position: relative;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    max-width: 400px;
    padding: 15px;
    background-color: var(--color-white);

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &.notification-info {
        .dm-notification-box__icon {
            img,svg,i  {
                color:var(--color-info);
            }
        }
    }

    &.notification-success {
        .dm-notification-box__icon {
            svg,i {
               color:var(--color-success);
            }
        }
    }

    &.notification-danger {
        .dm-notification-box__icon {
            svg,i  {
                color:var(--color-danger);
            }
        }
    }

    &.notification-warning {
        .dm-notification-box__icon {
            svg,i  {
                color:var(--color-warning);
            }
        }
    }

    @include e("icon") {
        margin-right: 15px;
        i{
            font-size: 24px;
            display: inline-flex;
        }
    }

    @include e("text") {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 15px;
        }
    }

    @include e("close") {
        position: absolute;
        top: 10px;
        right: 15px;
        i,
        svg,
        img {
            max-width: 20px;
            color: var(--color-dark);
           @include rfs(18px);
        }
    }
}

// Notification Placement
.notification-placement-wrap {
    margin: -10px;

    @include xs {
        flex-flow: column;
    }

    .dm-select {
        min-width: 140px;
        margin: 10px;
    }
}