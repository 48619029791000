.filter {
  padding: 24px 24px;
  position: fixed;
  height: 100vh;
  width: 300px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background-color: #fff;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 12px;
  transition: transform 0.3s;
  transform: translateX(-100%);
  &--active {
    transform: translateX(0%);
  }
}
.filter-list {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px !important;
  }
}
.filter-group {
  margin-bottom: 30px;
}
.filter-group-title {
  margin-bottom: 20px;
}
.filter-btn {
  //   position: absolute;
  //   bottom: 24px;
  //   left: 24px;
  //   width: calc(100% - 48px);
  width: 100%;
}
.filter-item {
  margin: 0px 10px 0px 10px;
  // display: flex;
  // align-items: center;
  // & span {
  //   font-weight: 500 !important;
  //   color: var(--color-dark);
  // }
  & + .filter-item {
    margin-top: 15px;
  }
}
.filter-title {
  margin-bottom: 12px;
}
