// Empty Css

.dm-empty{
    @include e("text"){
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        color: var(--color-dark);
        p{
            @include ofs(14px, lh(14px, 22px), 400);
            margin-bottom:0;
            &.disabled{
                color:var(--color-lighten);
            }
        }
    }
    @include e("image"){
        img{
            max-width: 130px;
        }
    }

    @include e("action"){
        margin-top: 17px;
    }
}