// Select Css
.dm-select-list{
    .dm-select + .dm-select{
        margin-left: 10px;
    }
}

// Select2 Basic 
.dm-select{
    min-width: 110px;
    .select2-selection {
        height: 42px;
    }
    .select2-selection[aria-labelledby="select2-select-search-container"]{
        min-width: 200px;
    }
    .select2-selection__arrow{
        display: none;
    }
}

// Select Disabled
.dm-select{
    &.disabled{
        .form-control{
            border:1px solid var(--border-deep);
            background-color:var(--bg-lighter);
        }
        select{
            cursor: not-allowed;
            appearance: none;
            color:var(--color-lighten);
        }
    }
}

// Select Arrow
.dm-select{
    position: relative;
    font-weight: 900;
    &:after{
        font-size: 10px;
        font-family: "Line Awesome Free";
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        content: '\f107';
    }
    select{
        appearance: none;
    }
}

// Select Size Controll Tab
.size-control-nav{
    .nav-item{
        margin-bottom: 16px;
        &:first-child{
            .nav-link{
                border-radius: 4px 0 0 4px;
            }
        }
        &:last-child{
            .nav-link{
                border-radius: 0 4px 4px 0;
                border-right-width: 1px;
            }
        }
        .nav-link{
            font-size: 14px;
            font-weight: 500;
            padding: .469rem .8rem;
            border-width: 1px;
            border-right-width: 0;
            border-style: solid;
            color:var(--color-light);
            border:1px solid var(--border-deep);
            &.active{
                border:1px solid var(--color-primary);
            }
        }
    }
}


// Select Size Content
.select-size-content{
    margin-top: 40px !important;
    @include e("single"){
        margin: -20px 0;
        .dm-select{
            margin: 20px 0;
        }
    }
}

//Select2 Default style
.select2-dropdown {
    background: var(--color-white);
    box-shadow: 0 5px 30px rgba(#1b1e2b, 0.15);
    padding: 15px 0;
    z-index: 1;
    border: none;
    z-index: 33;
    .select2-results__option {
        font-size: 14px;
        display: block;
        line-height: 1.1;
        font-weight: 400;
        padding: 10px 25px;
        color:var(--color-gray);
    }
}
.select2-dropdown .select2-results__option{
    transition: var(--transition);
}

.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: var(--white-06);
    color:var(--color-primary);
}


select.select-arrow-none,
.was-validated .custom-select:invalid, .custom-select.is-invalid{
    background: none;
    &::-ms-expand {
        display: none;
    }
}

//spacing

.select-px-15{
    .select2-container--default .select2-selection--multiple .select2-selection__rendered,
    .select2-container .select2-selection--single .select2-selection__rendered{
        padding: 0 15px;
    }
}

//Global Select2 Style
.select2 {
    width: 100% !important;
  }
  
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--multiple {
    background-color: var(--color-white);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    height: 48px;
    display: flex;
    align-items: center;
  
    &:focus {
      outline: none;
    }
  }
  
  .select2-container--default
    .select2-search--inline
    .select2-search__field::placeholder {
    @include ofs(14px, lh(14px, 25px), 400);
    color:var(--color-gray);
  }
  
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  
    b {
      display: none;
    }
  
    &:after {
      content: "\f107";
      font-family: "Line Awesome Free";
      font-weight: 900;
      color:var(--color-gray);
      font-size: 12px;
    }
  }
  
  .select2-container--default.select2-container--open
    .select2-selection--single
    .select2-selection__arrow {
    &:after {
      content: "\f106";
      font-family: "Line Awesome Free";
      font-weight: 900;
      color:var(--color-gray);
      font-size: 12px;
    }
  }
  
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 20px;
  }
  
  .select2-container .select2-selection--single .select2-selection__rendered,
  .select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
    @include ofs(14px, lh(14px, 25px), 400);
    color:var(--color-gray);
    text-transform: capitalize;
  }
  
  .select2-results__option {
    text-transform: capitalize;
  }
  
  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 100%;
  }
  
  .select2-container .select2-selection--single .select2-selection__clear {
    right: 0;
    display: none;
  }
  
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    margin-top: 0;
  }
  
  .select2-container .select2-search--inline .select2-search__field {
    margin-top: 0;
  }
  
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background: #eff0f3;
    border: none;
    border-radius: 4px;
    height: 24px;
    @extend .content-center;
    @include ofs(11px, lh(11px, 14px), 400);
    color:var(--color-gray);
    text-transform: capitalize;
  }
  
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__rendered {
    padding: 0 20px;
  }
  
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__clear {
    margin-top: 0;
    padding: 0;
  }
  
  .select2-container--default.select2-container--focus
    .select2-selection--multiple,
  .select2-container--default.select2-container--focus
    .select2-selection--single {
    border: 1px solid var(--color-primary);
  }