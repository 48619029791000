.wrap {
  display: grid;

  grid-template-rows: calc(100vh - 325px) 64px;
  row-gap: 100px;
  padding-top: 102px;
  position: relative;
}
.messageList {
  overflow: auto;
  //   transition-delay: 300;
  //   transition: ;
  transform: translateY(-10px);
  opacity: 0;
  animation: smooth-appear 0.3s ease forwards;
}

@keyframes smooth-appear {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.emptyMessages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #535354;
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .wrap {
    padding-top: 68px;
    row-gap: 68px;
  }
  .messageList {
    padding: 0 10px;
  }
}
