.wrap {
  background-color: #1e1f20;
  padding: 32px 17px;
  width: 68px;
  transition: all 0.3s;
  height: 100vh;
  z-index: 100;
}

.wrapShow {
  width: 272px;
}
.header {
}
.menuBtn {
  height: 34px;
  width: 34px;
  background-position: center;
  background-size: 14px 10px;
  background-repeat: no-repeat;
  background-image: url(../../../../public/img/svg/burger.svg);
}
.menuBtnClose {
  height: 34px;
  width: 34px;

  background-position: center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-image: url(../../../../public/img/svg/close-2.svg);
  border-radius: 50%;
  background-color: #37393b;
}
.newChatWrap {
  background: #1a1a1c;

  height: 34px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 68px;
  padding-left: 0px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    & .newChatText {
      color: rgba(255, 255, 255, 1);
    }
    & .newChat {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.newChatTextShow {
  padding-left: 41px;
  opacity: 1 !important;
}
.newChatText {
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #535354;
  white-space: nowrap;
  transition: all 0.3s;
  opacity: 0;
}
.newChatWrapDisabled {
  pointer-events: none;
  opacity: 0.5;
}
.newChat {
  width: 34px;
  height: 34px;
  // background: #1a1a1c;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  mask-position: center;
  mask-size: 14px 14px;
  mask-repeat: no-repeat;
  transition: all 0.3s;
  mask-image: url(../../../../public/img/svg/add.svg);
  background-color: #666667;
}
.recentTitle {
  user-select: none;

  margin-top: 28px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 17px;
}
.list {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 258px);
  width: calc(100% + 12px);
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
}
.itemActive {
  background-color: #37393b;
}
.item {
  height: 51px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  padding: 0 19px;
  user-select: none;
  border-radius: 100px;

  width: 238px;
  transition: all 0.3s;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  & div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover {
    color: #fff;
  }
  &::before {
    content: '';
    display: block;
    width: 14px;
    height: 12px;
    min-width: 14px;
    min-height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../../public/img/svg/comp.svg);
    margin-right: 17px;
  }
}

.icon {
}
.history {
  transition: opacity 0.3s;
  opacity: 0;
}
.historyShow {
  opacity: 1;
}
.itemName {
}
@media (max-width: 992px) {
  .wrap {
    width: 300px;
    position: fixed;
    transform: translateX(-100%);
  }
  .header {
  }
  .wrapShow {
    transform: translateX(0%);
  }
}
