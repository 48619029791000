.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1f20;
  height: 64px;
  box-shadow: 0px 0px 2px 0px #00000040;
  border-radius: 100px;
  padding: 0 8px 0 34px;
}
.input {
  font-size: 17px;
  font-weight: 300;
  line-height: 17px;
  width: 100%;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
  padding: 0 20px 3px 0;
  &::placeholder {
    user-select: none;
    color: rgba(255, 255, 255, 0.4);
  }
}

.sendDisabled {
  opacity: 0.5;
}

.send {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 55% center;
  background-image: url(../../../../public/img/svg/telegram.svg);
  background-color: #37393b;
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
  transition: all 0.3s;

  cursor: pointer;
  &:hover {
    transform: scale(1.35);
  }
}
