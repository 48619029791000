// Page Header
.page-title-wrap{
    padding: 20px 25px 17px;
    border-radius: 5px;
    .dm-breadcrumb{
        margin-bottom: 18px;
    }
    .dm-breadcrumb__item{
        &:first-child{
            a{
                color:var(--color-light);
                &:hover{
                    color:var(--color-primary);
                }
            }
        }
        &:last-child{
            span{
                color:var(--color-gray);
            }
        }
        a{
            color:var(--color-light);
            &:hover{
                color:var(--color-primary);
            }
        }
    }
}

// Page Title Action
.page-title{
    @include sm{
        flex-flow: column;
    }
    @include e("right"){
        @include sm{
            margin-top: 12px;
        }
        .page-top-action{
            flex-wrap: wrap;
            margin: -5px;
            a{
                margin: 5px;
                min-height:34px;
            }
            .btn-xs{
                padding: 0 1.134rem;
                @include ofs(14px, lh(14px, 22px), 400);
            }
        }
    }
}
// Page Title Bordered
.page-title-wrap{
    &.wrapper-bordered{
        border:1px solid var(--border-light); 
    }
}
.page-title-block{
    border-radius: 5px;
    &.bg-container{
        padding: 25px;
    }
    .page-title-bg{
        border-radius: 5px;
        background-color: var(--bg-white);
    }
}
// Page Title
.page-title{
    @include e("left"){
        display: flex;
        align-items: center;
        a{
            i,
            svg,
            img,
            span{
                width: 16px;
                color: #000000;
            }
            i{
                @include rfs(20px);
                margin-top: 3px;
                margin-right: 9px;
            }
        }
        .title-text{
            @include rfs(22px);
            font-weight: 600;
            color: var(--color-dark);
        }
        .title-text{
            margin-left: 8px;
        }
        .sub-title{
            font-size: 14px;
            margin-left: 10px;
            color:var(--color-lighten);
        }
    }
}

// Page Info
.page-info{
    padding:0 30px 12px 30px;
    @include sm{
        flex-flow: column;
    }
    @include e("item"){
        flex: 0 0 32%;
    }
    @include e("single"){
        margin-bottom: 8px;
        span{
            font-size: 14px;
            color: var(--color-dark);
            &.info-text{
                color:var(--color-gray);
                &.color-primary{
                    color:var(--color-primary);
                }
            }
        }
        .info-list{
            span{
                color:var(--color-light);
            }
        }
    }
}