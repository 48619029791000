// Tooltip Css
.tooltip {
  .arrow {
    position: relative;
    width: 12px;
    height: 8px;
    border-left: 1px solid transparent;
    border-bottom: 1px solid var(--border-light);
    &:before {
      display: block;
      box-sizing: border-box;
      border-right: 3px solid transparent;
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-left: 1px solid var(--border-light);
      border-top: 1px solid var(--border-light);
      border-bottom: 3px solid transparent;
      transform: rotate(45deg) skew(10deg, 10deg);
      left:0;
      bottom: -6px;
      color:var(--color-white);
    }
  }
  .tooltip-inner {
    border: 1px solid var(--border-light);
    box-shadow: 0 8px 15px rgba(var(--light-gray-rgba), 0.15);
  }
}
