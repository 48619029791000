// Todo List
.todo-list {
  td {
    padding: 12px 25px !important;
  }
  @include e(right) {
    li {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    li a {
      svg,
      i,
      img {
        width: 16px;
        color:var(--color-lighten);
      }
      &.active {
        svg,
        i {
          color:var(--color-warning);
        }
      }
    }
  }
  .custom-checkbox {
    input[type="checkbox"] {
      &:checked + label {
        color: var(--color-primary) !important;
      }
      + label {
        color: var(--color-gray) !important;
      }
    }
  }
}

// Add task Action
.add-task-action {
  margin: 15px 0 0 25px;
  button {
    font-size: 12px;
    width: auto;
    background-color: rgba(var(--color-primary-rgba), 0.08);
  }
}

// Add todo Modal
.add-todo-modal {
  .modal-header {
    .close {
      svg,
      img {
        width: 18px;
      }
    }
  }
}
