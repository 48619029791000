.support-form .support-form__search {
  margin-top: 0;
  margin-bottom: 0;
}
.support-form {
  margin-top: 5px;
  margin-bottom: 25px;
}
.userDatatable-content--subject {
  font-weight: 400 !important;
}
.userDatatable-title {
  text-transform: none;
}
.table > :not(caption) > * > * {
  background-color: auto;
}
