.wrap {
  display: grid;
  grid-template-columns: 34px 1fr;
  column-gap: 17px;
  align-items: start;
  justify-content: start;
  & + & {
    margin-top: 68px;
  }
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 34px;
  height: 34px;
  background: #1e1f20;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
}
.logo {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 55% center;
  background-image: url(../../../../public/img/svg/logo-icon.svg);
  height: 34px;
  width: 34px;
}
.text {
  font-size: 17px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
  vertical-align: top;
  padding-right: 43px;
  color: #fff;
}

$dot-width: 10px;
$dot-color: #fff;
$speed: 1s;

.loading {
  position: relative;
  align-self: flex-end;
  transform: translateY(-18px);
  span {
    content: '';
    animation: blink $speed infinite;
    animation-fill-mode: both;
    height: $dot-width;
    width: $dot-width;
    background: $dot-color;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: $dot-width * 1.5;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: $dot-width * 3;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
