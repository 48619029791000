.wrap {
  display: flex;
  align-items: center;
  margin-right: -15px;
  user-select: none;
  cursor: pointer;
  position: relative;
}
.wrapCode {
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  padding-bottom: 4px;
}
.generateNew {
  display: flex;
  align-items: center;
  font-size: 11px;
  text-decoration: underline;
  color: var(--color-success);
  // margin-top: -5px;
  position: absolute;
  white-space: nowrap;
  top: calc(100% - 8px);
  right: 4px;
}
.message {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 0px);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  color: #fff;
  padding: 3px 8px;
  font-size: 12px;
  transition: all 0.3s ease-out;
  opacity: 0;
  visibility: hidden;
  &Show {
    opacity: 1;
    visibility: visible;
  }
}

.code {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  // text-transform: uppercase;
  // margin-right: 8px;
}
.generate {
  line-height: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--color-success);
  font-size: 14px;
  & i {
    font-size: 20px;
    margin-right: 8px;
    margin-bottom: 1px;
  }
}
.copy {
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  margin-left: 8px;
}
