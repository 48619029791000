/* table social metric */
.table-social {
  min-height: 382px;

  th {
    border-top: 0 none !important;
    border-bottom: 0 none !important;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-dark);
    background-color: var(--bg-normal);
  }

  tr {

    th:first-child,
    td:first-child {
      border-left: 0 none;
    }

    th:last-child,
    td:last-child {
      border-right: 0 none;
    }

    td:first-child {
      padding-left: 25px;
    }

    td:last-child {
      padding-right: 25px;
    }
  }

  tbody {
    tr:not(:first-child) {
      transition: var(--transition);

      &:hover {
        background: var(--bg-normal);
      }

      color:var(--color-gray);
    }

    tr:first-child {
      td {
        color: var(--color-dark);
        font-weight: 500;
      }
    }

    tr td:not(:first-child) {
      text-align: right;
    }
  }

  a {
    color: var(--color-info);

    &:hover {
      color: var(--color-info);
    }
  }

  td {
    font-size: 14px;
  }

  th,
  td {
    padding: 12.8px 15px;
  }
}

.top-menu {
  .table-social {
    margin-bottom: 64px;

    @include xxl {
      margin-bottom: 15px;
    }
  }
}

/* table Landing Pages */
.landing-pages-table {
  @include xxl {
    padding-bottom: 58px;
  }

  @include xl {
    padding-bottom: 40px;
  }

  @include lg {
    padding-bottom: 0;
  }

  table tbody {
    td {
      a {
        white-space: nowrap;

        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
}

/* table /.traffic channel */
.table--default {
  &.body-px-25 {

    th:first-child,
    td:first-child {
      padding-left: 25px;
    }

    th:last-child,
    td:last-child {
      padding-right: 25px;
    }
  }

  th {
    border-top: 0 none !important;
    border-bottom: 0 none !important;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--color-dark);
  }

  tr {

    th:first-child,
    td:first-child {
      border-left: 0 none;
    }

    th:last-child,
    td:last-child {
      border-right: 0 none;
    }
  }

  tbody {
    tr {
      transition: var(--transition);
      color: var(--color-gray);

      &:last-child {
        border-bottom: 1px solid var(--border-color);
      }
    }

    tr td:not(:first-child) {
      text-align: right;
    }
  }

  thead tr th:not(:first-child) {
    text-align: right;
  }

  td {
    font-size: 14px;
    vertical-align: middle;
  }

  th,
  td {
    padding: 16px 15px;
  }
}

.table-borderless {
  tr {
    &:last-child {
      border-bottom: 0 none !important;
    }
  }
}

.traffic-table {
  .progress {
    height: 5px;
  }

  &.table--default thead tr th:not(:first-child) {
    text-align: left;
  }
  &.table--default tbody tr td:not(:first-child) {
    text-align: left;
  }
}

.top-menu {
  .traffic-table {
    margin-bottom: 35px;
  }
}

/* table top region */
.table-top-regions {
  border: 1px solid var(--border-color);
  max-height: 280px;
  max-width: 466px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid var(--scrollbarBG);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: var(--thumbBG);
  }


  @include xxl {
    max-width: 400px;
  }

  @include sm {
    margin: 0 auto 25px;
  }

  table {
    margin-bottom: 0;

    thead {
      background: var(--bg-normal);
    }

    th {
      padding: 11px 15px;
      color: var(--color-light);
      font-size: 12px;
      font-weight: 500;
      line-height: 19px;
      text-transform: uppercase;
    }

    td {
      padding: 8px 15px;
      font-size: 14px;
      line-height: lh(14px, 28px);
      font-weight: 400;
      color: var(--color-dark);
    }

    tbody {
      tr {
        &:first-child {
          td {
            padding-top: 20px;
          }
        }

        &:last-child {
          td {
            padding-bottom: 12px;
          }
        }

        td:first-child {
          font-size: 15px;
          font-weight: 500;
          color: var(--color-dark);
        }
      }
    }
  }
}

/* table top selling location  */
.table-top-location {
  border-top: 1px solid var(--border-color);
  margin-top: 18px;
  padding-top: 10px;
  margin-bottom: -8px;

  table {
    thead {
      th {
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        color: var(--color-lighten);
      }
    }

    tbody {
      tr {
        &:hover {
          background: var(--color-white);

          td {
            background-color: var(--color-white);
          }
        }
      }
    }

    th,
    td {
      padding: 7px 0 !important;
    }
  }
}

.top-menu {
  .table-top-location {
    margin-bottom: 38px;

    @include xxl {
      margin-bottom: 0;
    }
  }
}

/* Table Revenue  */
.table-revenue {
  min-height: 370px;

  @include lg {
    min-height: 100%;
  }

  th {
    padding: 15px 18px;
  }

  td {
    padding: 10px 18px;
  }
}

.top-menu {
  .table-revenue {
    margin-bottom: 42px;

    @include xxl {
      margin-bottom: 0;
    }
  }
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: var(--border-color);
}

.table>tbody {
  vertical-align: middle;
}