// this file contains the necessaru scss for the custm scrollbar
@mixin custom_scroll($selector){
  #{$selector}::-webkit-scrollbar-track {
    background-color: rgba(var(--color-dark-rgba), .05);
  }
  #{$selector}::-webkit-scrollbar {
    width: 3px;
    background-color: transparent
  }

  #{$selector}::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
    -webkit-border-radius: 5px;
    border-radius: 5px
  }
}

@include custom_scroll('.vertical-tabs');

@include custom_scroll('.search-body');

@include custom_scroll('.chat-box');

@include custom_scroll('.scroll-timeline');
