.DateInput_input {
  border: none !important;
  font-family: 'Jost' !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  box-shadow: none !important;
  text-align: center;
  &::placeholder {
    color: #adb4d2;
  }
}
.DateInput {
  width: 235px !important;
  text-align: center;
}
.DateRangePicker {
  height: 44px;
}
.DateRangePicker_picker {
  z-index: 11 !important;
}
.DateRangePickerInput_arrow {
  &::after {
    content: '';
    display: block;
    width: 17px;
    height: 17px;
    mask-image: url(../../../../../public/img/arrow.svg);
    background-color: #8c90a4;
    transform: translateY(-2px);
  }
}

.DateRangePickerInput_arrow_svg {
  display: none;
}
.DateRangePickerInput {
  border: 1px solid #f1f2f6 !important;
  border-radius: 5px !important;
  overflow: hidden;
}
.DateRangePickerInput_arrow + .DateInput.DateInput_1 {
  position: relative;
  width: 255px !important;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12.5px;
    width: 15px;
    height: 15px;
    mask-image: url(../../../../../public/img/calendar.svg);
    background-color: #8c90a4;
  }
}
.CalendarDay {
  border-radius: 50%;
  border: none !important;
}
.DateRangePicker_picker {
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  border-radius: 8px !important;
  overflow: hidden;
}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: var(--color-primary) !important;
}
.CalendarDay__selected_span {
  background-color: rgba(var(--color-primary-rgba), 0.15) !important;
  color: #000 !important;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: rgba(var(--color-primary-rgba), 0.15) !important;
  color: #000 !important;
}
.CalendarDay__default {
}
.DayPickerNavigation_leftButton__horizontalDefault {
  padding: 0 !important;
  border: none !important;
  position: relative;
  width: 32px;
  height: 32px;
  & svg {
    display: none;
  }
  &::after {
    content: '';
    display: block;
    mask-image: url(../../../../../public/img/svg/chevron-left.svg);
    mask-size: contain;
    background-color: var(--color-dark);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.DayPickerNavigation_rightButton__horizontalDefault {
  padding: 0 !important;
  border: none !important;
  position: relative;
  width: 32px;
  height: 32px;
  background: none !important;
  & svg {
    display: none;
  }
  &::after {
    content: '';
    display: block;
    mask-image: url(../../../../../public/img/svg/chevron-right.svg);
    mask-size: contain;
    background-color: var(--color-dark);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
