// Alert 

.alert {
    display: flex;
    min-height: 44px;

    &.alert-icon-area {
        align-items: center;
        padding: 0.5313rem 1.25rem;

        .alert-icon {
            img,
            svg {
                margin-top: 1px;
            }
        }
    }

    &.alert-icon-big {
        padding: 1.125rem 1.25rem;
    }
    &.alert-big{
        padding:0.8125rem 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .alert-icon {
        margin-right: 10px;
        display: flex;
        img,
        svg {
            width: 15px;
            margin-top: 0;
        }
    }

    .alert-heading {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    p {
        font-size: 14px;
        margin-bottom: -1px;
    }

    &.alert-dismissible {
        .btn-close {
            padding: 10px 20px;
            background: transparent;
            width: auto;

            span,
            i {
                font-size: 12px;
                font-weight: 500;
                display: block;
                line-height: 1.9;
            }

            svg,
            img {
                width: 12px;
            }
        }
    }
}