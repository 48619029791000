.custom-select,
.custom-select-small {
  &__control {
    min-width: 120px;
    // margin-top: -2px;
    min-height: 50px !important;
    height: 50px !important;

    font-size: 15px !important;
    border-radius: 0.3rem !important;
    width: 100% !important;
    padding: 8px 16px 8px 14px !important;
    font-weight: 400 !important;
    color: var(--color-dark) !important;
    background-color: var(--input-bg) !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;
    border: 1px solid var(--border-light) !important;
    appearance: none !important;
    border-radius: 5px !important;
    box-shadow: 0 0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 7px;
      mask-image: url(../../../../public/img/arrow-down.svg);
      background-color: #8c90a4;
      position: absolute;
      top: 20px;

      right: 12px;
    }
  }
  &__value-container,
  &__input-container {
    margin: 0 !important;
    padding: 0 !important;
  }
  &__indicators {
    display: none !important;
  }
  &__placeholder {
    color: #bfbfbf !important;
    font-weight: 400;
  }
  &__option--is-focused {
    background-color: rgba(var(--color-primary-rgba), 0.1) !important;
  }
  &__option {
    color: #0a0a0a !important;
  }
}

.form-control-small {
  min-height: 40px !important;
  padding-left: 12px;
  padding-right: 12px;
}
.form-control {
  &::placeholder {
    color: #bfbfbf !important;
  }
}

.custom-select-small {
  &__control {
    min-height: 40px !important;
    height: 40px !important;
    padding-right: 12px !important;
    &::after {
      top: 17px;
    }
  }
}
.custom-checkbox {
  // margin: 0px 10px 0px 10px;
  display: flex;
  align-items: center;
  & span {
    font-weight: 500 !important;
    color: var(--color-dark);
    line-height: 18px;
  }

  & + .filter-item {
    margin-top: 15px;
  }
}
