.title {
  font-size: 68px;
  font-weight: 500;
  line-height: 68px;
  text-align: left;
  color: #454746;

  margin-bottom: 67px;
  & span {
    background: linear-gradient(90deg, #37327d 0%, #00b4f5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 17px;
  // justify-content: space-between;
  margin-bottom: 100px;
}
.wrap {
  display: grid;
  // row-gap: 250px;
  grid-template-rows: calc(100vh - 189px) 64px;
}
.content {
  transform: translateY(0px);
  margin-top: 104px;
  overflow: auto;
}
.contentHide {
  animation: smooth-appear 0.3s ease forwards;
}
@keyframes smooth-appear {
  to {
    transform: translateY(30px);
    opacity: 0;
  }
}
.item {
  position: relative;
  background-color: #1e1f20;
  aspect-ratio: 1 / 1;
  // height: 262px;
  border-radius: 17px;
  padding: 17px 17px 34px 17px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(0.95);
  }
}
.itemName {
  font-size: 17px;
  font-weight: 300;
  line-height: 17px;
  text-align: left;
}
.itemIcon {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 17px;
  right: 17px;
  background-color: #131314;
  border-radius: 50%;
  width: 34px;
  height: 34px;
}
@media (max-width: 992px) {
  .title {
    font-size: 51px;
    line-height: 51px;
    margin-bottom: 45px;
  }
  .content {
    overflow-x: hidden;
    padding: 0 10px 0 10px;
    margin-top: 68px;
  }
  .item {
    min-width: 240px;
  }
  .items {
    overflow: auto;
    // padding-bottom: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
